import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MAX_WIDTH } from '../../../utils/constants';

const ProductTypeWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  .product-type-item {
    height: 456px;
    text-decoration: none;
    display: flex;
    justify-content: center;
  }
  p {
    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: black;
  }
`;

const ProductType = ({ product_types }) => {
  return (
    <ProductTypeWrapper>
      {product_types.map((product_type) => {
        const { id, name, description, url, producttype_images } = product_type;
        return (
          <a key={id} href={url} className="product-type-item">
            <p>{name}</p>
          </a>
        );
      })}
    </ProductTypeWrapper>
  );
};

ProductType.propTypes = {};

export default ProductType;
