import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const query = graphql`
  query PageProduct {
    strapiPageProduct {
      banner_text {
        title
        description
        background {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
      product_types {
        id
        name
        description
        url
        producttype_images {
          url
        }
      }
    }
  }
`;

export default function PageProduct({ renderContent }) {
  return <StaticQuery query={query} render={(data) => renderContent(data)} />;
}
