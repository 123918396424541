import React from 'react';
import styled from 'styled-components';
import PageProduct from '../queries/page-product';
import SiteLayout from '../components/SiteLayout';
import { BannerImageText } from '../components/common/';
import ProductType from '../components/page/ProductPage/ProductType';
import { useScrolldown } from '../hooks/useScrolldown';

const ProductWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function Product() {
  useScrolldown('#banner_wrapper');

  const renderContent = ({ strapiPageProduct }) => {
    const { banner_text, product_types } = strapiPageProduct;
    return (
      <ProductWrapper>
        <BannerImageText banner_text={banner_text} />
        <ProductType product_types={product_types} />
      </ProductWrapper>
    );
  };

  return (
    <SiteLayout activeKey={'PRODUCT'}>
      <PageProduct renderContent={renderContent} />
    </SiteLayout>
  );
}
