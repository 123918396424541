import { useRef, useEffect } from 'react';

export const useScrolldown = (keyVisualElementId) => {
  const keyVisualElementHeight = useRef(0);

  const onScrollDown = () => {
    window.scrollTo({
      top: keyVisualElementHeight.current + 1,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const updateKeyVisualHeight = () => {
      const KeyVisualDivElement = document.querySelector(keyVisualElementId);
      if (KeyVisualDivElement) {
        const currentKeyVisualElementHeight = KeyVisualDivElement.clientHeight;
        keyVisualElementHeight.current = currentKeyVisualElementHeight;
      }
    };

    const toggleStyle = (style) => {
      const element = document.querySelector('#layout-navbar');
      if (element) {
        if (style === 'white') {
          element.classList.contains('transparent') ? element.classList.remove('transparent') : null;
          element.classList.add('white');
        }
        if (style === 'transparent') {
          element.classList.contains('white') ? element.classList.remove('white') : null;
          element.classList.add('transparent');
        }
      }
    };

    const changeNavbarStyle = () => {
      if (window.scrollY > keyVisualElementHeight.current) {
        toggleStyle('white');
      } else {
        toggleStyle('transparent');
      }
    };
    updateKeyVisualHeight();
    changeNavbarStyle();
    window.addEventListener('resize', updateKeyVisualHeight);
    window.addEventListener('scroll', changeNavbarStyle);
    return () => {
      window.removeEventListener('resize', updateKeyVisualHeight);
      window.removeEventListener('scroll', changeNavbarStyle);
    };
  }, []);

  return { onScrollDown };
};
